import React, { useContext, useState, useEffect } from "react";
import { AlertConstants } from "best-common-react";
import styled from "styled-components";
import WBCPrimaryOutlinedButton from "../../elements/WBCPrimaryOutlinedButton";
import PropTypes from "prop-types";
import WBCYearsContext from "../../../contexts/WBCYearsContext";
import InterestListApi from "../../../httpClients/InterestListApi";
import WBCProfileApi from "../../../httpClients/WBCProfileApi";
import ProspectivePlayerContext from "../../../contexts/ProspectivePlayerContext";
import SelectedTeamContext from "../../../contexts/SelectedTeamContext";
import RouteConstants from "../../../constants/RouteConstants";
import { withRouter } from "react-router-dom";
import ProspectivePlayersApi from "../../../httpClients/ProspectivePlayersApi";
import GlobalModalContext from "../../../contexts/GlobalModalContext";
import { useError } from "../../hooks/useError";
import { useAlert } from "../../hooks/useAlert";

const TableButton = styled(WBCPrimaryOutlinedButton)`
  &&& {
    width: 100%;
    height: 100%;
  }
`;

const ButtonText = styled.div`
  padding-top: 4px;
  padding-left: 6px;
`;

const AddInterestContainer = styled.div`
  color: ${props => props.theme[props.isOnInterestList ? "grey" : "wbcBlue"]};
  cursor: ${props => (props.isOnInterestList ? "no-drop" : "pointer")};
  text-align: center;
`;

const PlayerMatchModalAddButton = withRouter(({ history, data, toggleModal }) => {
  // hook(s)
  const globalModalContext = useContext(GlobalModalContext);
  const { selectedYear } = useContext(WBCYearsContext);
  const selectedTeamContext = useContext(SelectedTeamContext);
  const prospectivePlayerContext = useContext(ProspectivePlayerContext);
  const showErrors = useError();
  const showAlert = useAlert();
  const [isOnInterestList, setOnInterestList] = useState(false);
  const [addingToInterestList, setAddingToInterestList] = useState(false);
  const [copyingProfile, setCopyingProfile] = useState(false);
  const [importingProfile, setImportingProfile] = useState(false);

  // variables
  const { teamId: fedTeamId } = selectedTeamContext.state.team;

  useEffect(() => {
    if (data.profileId) {
      InterestListApi.isOnInterestList(selectedYear, fedTeamId, data.profileId)
        .then(data => {
          typeof data === "boolean" ? setOnInterestList(data) : setOnInterestList(false);
        })
        .catch(response => {
          showAlert(response.toString(), AlertConstants.TYPES.DANGER);
        });
    }
  }, [data.profileId, selectedYear, fedTeamId, showAlert]);

  // function(s)
  const closeGlobalModal = () => globalModalContext.dispatch({ type: "closeModal" });
  const addToInterestOverride = () => {
    const profileId = data.profileId;
    InterestListApi.addPlayerToInterestList(selectedYear, fedTeamId, profileId, true)
      .then(() => {
        showAlert("Player added to Interest List");
        toggleModal(false);
        prospectivePlayerContext.dispatch({
          type: "addPlayerToInterestList",
          profileId
        });
        closeGlobalModal();
      })
      .catch(({ response }) => {
        showAlert(response.data.message, AlertConstants.TYPES.WARNING);
        toggleModal(false);
        closeGlobalModal();
      });
  };

  // button functions
  const addToInterestList = () => {
    if (!addingToInterestList) {
      setAddingToInterestList(true);

      const profileId = data.profileId;
      InterestListApi.addPlayerToInterestList(selectedYear, fedTeamId, profileId)
        .then(() => {
          setAddingToInterestList(false);
          showAlert("Player added to Interest List");
          prospectivePlayerContext.dispatch({
            type: "addPlayerToInterestList",
            profileId
          });
          toggleModal(false);
        })
        .catch(({ response }) => {
          setAddingToInterestList(false);

          // TODO: make back end return custom error response code
          if (response.data.message.includes("is already on the interest list")) {
            showAlert("This player is already on your Interest List", AlertConstants.TYPES.WARNING);
            toggleModal(false);
          } else {
            showErrors(response.data.message, addToInterestOverride);
          }
        });
    }
  };

  const copyPlayerProfile = () => {
    if (!copyingProfile) {
      setCopyingProfile(true);

      WBCProfileApi.copyPlayerProfileToTeam(fedTeamId, data.profileId)
        .then(() => {
          setCopyingProfile(false);
          showAlert("Player saved");
          toggleModal(false);
          ProspectivePlayersApi.getProspectivePlayers(selectedYear, fedTeamId).then(response => {
            prospectivePlayerContext.dispatch({
              type: "setProspectivePlayers",
              prospectivePlayers: response
            });
          });
        })
        .catch(({ response }) => {
          setCopyingProfile(false);
          showAlert(response.data.message, AlertConstants.TYPES.DANGER);
        });
    }
  };

  const importPlayerFromEbis = () => {
    if (!importingProfile) {
      setImportingProfile(true);

      WBCProfileApi.copyEbisPlayerProfileToTeam(fedTeamId, data.ebisId)
        .then(profile => {
          setImportingProfile(false);
          showAlert("Player saved");
          history.push(
            RouteConstants.PROFILE.replace(":playerId", profile.profileId)
              .replace(":fedTeamId", fedTeamId)
              .replace(":profileType", RouteConstants.PLAYER)
          );
        })
        .catch(() => {
          setImportingProfile(false);
          showAlert("Error while saving profile", AlertConstants.TYPES.DANGER);
        });
    }
  };

  // determine which function actually handles the click
  const onClick = () => {
    if (data.isOnOwnFedTeam) return addToInterestList;
    if (!data.profileId) return importPlayerFromEbis;
    return copyPlayerProfile;
  };

  // generate button content
  const buttonContent = () => {
    if (data.isOnOwnFedTeam) {
      return (
        <AddInterestContainer isOnInterestList={isOnInterestList}>
          <div className={"d-flex"}>
            <i style={{ marginTop: "6px" }} className="fas fa-user-plus" />
            <ButtonText>Interest List</ButtonText>
          </div>
        </AddInterestContainer>
      );
    }
    return !data.profileId ? "Import Profile" : "Add Player";
  };

  // component
  return (
    <TableButton disabled={isOnInterestList && data.isOnOwnFedTeam} onClick={onClick()}>
      {buttonContent()}
    </TableButton>
  );
});

PlayerMatchModalAddButton.propTypes = {
  data: PropTypes.object,
  toggleModal: PropTypes.func
};

export default PlayerMatchModalAddButton;
