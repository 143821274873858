import React, { useContext, useEffect, useCallback, useState } from "react";
import { AlertConstants } from "best-common-react";
import ProspectivePlayersTable from "./ProspectivePlayersTable";
import ProspectivePlayerSearch from "./ProspectivePlayerSearch";
import WBCContent from "../elements/WBCContent";
import NewPlayerCard from "./NewPlayerCard";
import ProspectivePlayerContext from "../../contexts/ProspectivePlayerContext";
import InterestListContext from "../../contexts/InterestListContext";
import styled from "styled-components";
import ProspectivePlayersApi from "../../httpClients/ProspectivePlayersApi";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import WBCAdminAccess from "../protected/WBCAdminAccess";
import WBCCollapse from "../elements/WBCCollapse";
import MobileFullWidth from "../elements/MobileFullWidth";
import { sortPlayers } from "../roster/RosterSortHelper";
import ExportApi from "../../httpClients/ExportApi";
import FileHelper from "../../httpClients/FileHelper";
import TableSortFilterContext from "../../contexts/TableSortFilterContext";
import { WBCPrimaryButton } from "../elements/WBCPrimaryButton";
import { useAlert } from "../hooks/useAlert";
import AuthContext from "../../contexts/AuthContext";

const QuickSearchContainer = styled(MobileFullWidth)`
  width: 240px;
`;

// make all contents to the right

const SyncButton = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 6rem;
`;

const Export = styled.i.attrs(() => ({
  className: "fas fa-download"
}))`
  cursor: pointer;
  color: ${props => props.theme.wbcBlue};
  &&& {
    line-height: inherit;
  }
`;

const ExportWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 4px;
`;

const ProspectivePlayersFormBody = () => {
  // contexts
  const { selectedYear } = useContext(WBCYearsContext);
  const selectedTeamContext = useContext(SelectedTeamContext);
  const interestListContext = useContext(InterestListContext);
  const tableSortFilterContext = useContext(TableSortFilterContext);
  const prospectivePlayerContext = useContext(ProspectivePlayerContext);
  const showAlert = useAlert();
  const { isFedAdmin, isBOCadmin, isTeamCoordinator } = useContext(AuthContext).state;

  const canPullPlayers = isFedAdmin || isBOCadmin || isTeamCoordinator;

  // variables
  const [isDisabled, setIsDisabled] = useState(false);
  const { sortFilters } = tableSortFilterContext.state;
  const { playerFilter } = prospectivePlayerContext.state;
  const { teamId: fedTeamId, code: countryCode } = selectedTeamContext.state.team;
  const { dispatch: prospectiveDispatch } = prospectivePlayerContext;
  const { positionUpdates, removedPlayers } = interestListContext.state;

  // functions
  const exportFn = useCallback(() => {
    ExportApi.exportProspectivePlayersList(
      selectedYear,
      fedTeamId,
      sortFilters.col,
      sortFilters.dir,
      playerFilter,
      (url, headers) => ({ url, headers })
    ).then(data => {
      const test = new Blob([data.url], { type: data.headers["content-type"] });
      const url = window.URL.createObjectURL(test);
      FileHelper.downloadFile(url, data.headers);
    });
  }, [sortFilters, playerFilter, fedTeamId, selectedYear]);

  const handlePopulateProspectivePlayers = () => {
    setIsDisabled(true);
    if (selectedYear && countryCode) {
      ProspectivePlayersApi.updateProspectivePlayersList(selectedYear, countryCode.toUpperCase())
        .then(response => {
          prospectiveDispatch({
            type: "setProspectivePlayers",
            prospectivePlayers: sortPlayers("lastName", "ASC", response)
          });
          showAlert(`Players Pulled Successfully`, AlertConstants.TYPES.SUCCESS);
          setIsDisabled(false);
        })
        .catch(error => {
          console.error("::error::Error sending message:", error.message);
          showAlert(`Error occured while pulling players`, AlertConstants.TYPES.DANGER);
        });
    }
  };

  // effects
  // Get the list of players.
  useEffect(() => {
    if (fedTeamId && selectedYear) {
      ProspectivePlayersApi.getProspectivePlayers(selectedYear, fedTeamId).then(response => {
        prospectiveDispatch({
          type: "setProspectivePlayers",
          prospectivePlayers: sortPlayers("lastName", "ASC", response)
        });
      });
    }
  }, [fedTeamId, selectedYear, positionUpdates, removedPlayers, prospectiveDispatch]);

  useEffect(() => {
    prospectiveDispatch({ type: "setPlayerFilter", playerFilter: "" });
  }, [prospectiveDispatch]);

  return (
    <WBCContent>
      <div className="d-flex flex-wrap">
        <div className="mb-3 w-100">
          <QuickSearchContainer>
            <ProspectivePlayerSearch />
          </QuickSearchContainer>
        </div>
      </div>
      <WBCAdminAccess
        orElse={
          <ExportWrapper>
            <Export onClick={exportFn} />
          </ExportWrapper>
        }
      >
        <WBCCollapse exportable startsOpen={false} className="mb-3" title="Create a new player" exportFn={exportFn}>
          <NewPlayerCard />
        </WBCCollapse>
      </WBCAdminAccess>
      <ProspectivePlayersTable />
      {canPullPlayers && (
        <SyncButton>
          <WBCPrimaryButton width={220} onClick={handlePopulateProspectivePlayers} disabled={isDisabled}>
            Populate Prospective Players
          </WBCPrimaryButton>
        </SyncButton>
      )}
    </WBCContent>
  );
};

export default ProspectivePlayersFormBody;
