import React, { useContext } from "react";
import PropTypes from "prop-types";
import OrgsContext from "../../contexts/OrgsContext";

const OrgFormatter = ({ value }) => {
  const orgContext = useContext(OrgsContext);
  if (value && ["BOC", "MSB", "NONE", null, ""]?.includes(orgContext.state.orgIdToCode[value]?.toUpperCase())) {
    value = null;
  }
  return <div>{value ? orgContext.state.orgIdToCode[value] : null}</div>;
};

OrgFormatter.propTypes = {
  value: PropTypes.number
};

export default OrgFormatter;
