import React, { useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/AuthContext";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import { TOURNAMENT_STATUS_BY_ID } from "../../constants/TournamentStatusConstants";
import { QUALIFYING, TOURNAMENT_TYPE_BY_ID } from "../../constants/TournamentTypeConstants";
import QualifyingRosterApi from "../../httpClients/QualifyingRosterApi";
import MainRosterApi from "../../httpClients/MainRosterApi";
import PositionConstants from "../../constants/PositionConstants";
import CoachingStaffSection from "./CoachingStaffSection";

const CoachingStaff = () => {
  const { isBOC } = useContext(AuthContext).state;
  const { teamId: fedTeamId } = useContext(SelectedTeamContext).state.team;
  const { selectedWbcYear } = useContext(WBCYearsContext);

  const [personnel, setPersonnel] = useState({ coaches: [], managers: [], trainers: [], staff: [] });
  const [tournamentType, setTournamentType] = useState(null);
  const [tournamentSettings, setTournamentSettings] = useState(null);

  const isAssignedToTournament = useCallback(
    staffProfile => {
      if (!!selectedWbcYear?.generalRosterSettings?.tournamentTypeId) {
        const tournamentTypeId = selectedWbcYear?.generalRosterSettings?.tournamentTypeId;
        const assignment = staffProfile.tournaments[tournamentTypeId];
        return (
          assignment && [TOURNAMENT_STATUS_BY_ID.PROVISIONAL, TOURNAMENT_STATUS_BY_ID.FINAL].includes(assignment.status)
        );
      }
    },
    [selectedWbcYear]
  );

  const loadStaff = useCallback(() => {
    if (!fedTeamId || !tournamentType) {
      return;
    }

    const api = tournamentType === QUALIFYING ? QualifyingRosterApi : MainRosterApi;
    api.getTeamStaff(fedTeamId).then(response => {
      const personnelForFed = { staff: [], managers: [], coaches: [], trainers: [] };
      response.staff
        .filter(staff => isAssignedToTournament(staff))
        .forEach(staff => {
          if (PositionConstants.STAFF.COACH.positionIds.includes(staff?.stats?.positionId)) {
            personnelForFed.coaches.push(staff);
          } else if (PositionConstants.STAFF.MANAGER.positionIds.includes(staff?.stats?.positionId)) {
            personnelForFed.managers.push(staff);
          } else if (PositionConstants.STAFF.TRAINER.positionIds.includes(staff?.stats?.positionId)) {
            personnelForFed.trainers.push(staff);
          } else {
            personnelForFed.staff.push(staff);
          }
        });
      setPersonnel(personnelForFed);
    });
  }, [fedTeamId, isAssignedToTournament, tournamentType]);

  useEffect(() => {
    loadStaff();
  }, [loadStaff]);

  useEffect(() => {
    if (selectedWbcYear?.generalRosterSettings?.tournamentTypeId) {
      const selectedYearTournamentType = TOURNAMENT_TYPE_BY_ID[selectedWbcYear.generalRosterSettings.tournamentTypeId];
      const selectedYearTournamentSettings =
        selectedYearTournamentType === QUALIFYING
          ? selectedWbcYear.qualifyingTournamentSettings
          : selectedWbcYear.mainTournamentSettings;
      setTournamentType(selectedYearTournamentType);
      setTournamentSettings(selectedYearTournamentSettings);
    }
  }, [selectedWbcYear]);

  return (
    <div className="px-3 py-2">
      <CoachingStaffSection
        staff={{
          type: "Coaches",
          min: tournamentSettings?.coaches?.coachesMin,
          max: tournamentSettings?.coaches?.coachesMax
        }}
        isBOC={isBOC}
        personnel={personnel.coaches}
      />
      <CoachingStaffSection
        staff={{
          type: "Managers",
          min: tournamentSettings?.managers?.managersMin,
          max: tournamentSettings?.managers?.managersMax
        }}
        isBOC={isBOC}
        personnel={personnel.managers}
      />
      <CoachingStaffSection
        staff={{
          type: "Trainers",
          min: tournamentSettings?.trainers?.trainersMin,
          max: tournamentSettings?.trainers?.trainersMax
        }}
        isBOC={isBOC}
        personnel={personnel.trainers}
      />
    </div>
  );
};

export default CoachingStaff;
