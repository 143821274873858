import BaseApi from "./BaseApi";

export default class ChronicConditionsApi extends BaseApi {
  constructor() {
    if (new.target === ChronicConditionsApi) {
      throw new TypeError("Cannot construct ChronicConditionsApi instances directly");
    }
  }

  static getChronicConditionsByYear = year => this.get(`chronicconditions?wbcYear=${year}`);
  static getAllInsuranceStatuses = () => this.get(`chronicconditions/insuranceStatuses`);
  static addChronicConditionForPlayers = playerIds =>
    this.post(`chronicconditions/save?playerIds=${playerIds.join(",")}`);
}
