import React, { useContext } from "react";
import WBCDataTable from "../../data-table/WBCDataTable";
import PlayerLinkFormatter from "../../table/PlayerLinkFormatter";
import ChronicConditionsContext from "../../../contexts/ChronicConditionsContext";
import TableSortFilterContext from "../../../contexts/TableSortFilterContext";
import { sortPlayers } from "../../roster/RosterSortHelper";

const ChronicConditionsTable = () => {
  // context variables
  const chronicConditionContext = useContext(ChronicConditionsContext);
  const tableSortFilterContext = useContext(TableSortFilterContext);
  const { filteredPlayerConditions, playerConditions, sortFilters } = chronicConditionContext;

  const OrgClubFormatter = ({ value, row }) => {
    return row.org.toUpperCase() === "BOC" ? "" : value;
  };

  const columns = [
    { name: "Insurance Status", key: "insuranceStatus", width: 180, sortable: true },
    { name: "Last", key: "lastName", width: 180, sortable: true, formatter: PlayerLinkFormatter },
    { name: "First", key: "firstName", width: 180, sortable: true, formatter: PlayerLinkFormatter },
    { name: "Pos", key: "position", sortable: true, width: 75 },
    { name: "Org", key: "org", width: 75, sortable: true, formatter: OrgClubFormatter },
    { name: "Club", key: "club", width: 180, sortable: true, formatter: OrgClubFormatter },
    { name: "Roster Status", key: "rosterStatus", sortable: true, width: 150 },
    { name: "Federation", key: "federation", sortable: true, width: 600 }
  ];

  return (
    <WBCDataTable
      useBCR
      columns={columns}
      headerRowHeight={40}
      minHeight={400}
      rowHeight={45}
      data={filteredPlayerConditions}
      sortFunc={(col, dir) => {
        chronicConditionContext.dispatch({
          col,
          dir,
          type: "sortPlayers",
          playerConditions: sortPlayers(col, dir, [...playerConditions], false)
        });
        tableSortFilterContext.dispatch({
          type: "setSortFilters",
          sortFilters: { col, dir }
        });
      }}
      sortColumn={sortFilters.col}
      sortDirection={sortFilters.dir}
    />
  );
};

export default ChronicConditionsTable;
