import React, { useCallback, useContext, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { BestSelect, Input } from "best-common-react";
import WBCYearsContext from "../../../contexts/WBCYearsContext";
import { isCurrentOrPastTournamentYear } from "../../../utils/WBCYearPredicates";
import ChronicConditionsContext from "../../../contexts/ChronicConditionsContext";
import WBCPrimaryOutlinedButton from "../../elements/WBCPrimaryOutlinedButton";
import AddChronicConditionPlayerModal from "./AddChronicConditionPlayerModal";
import AuthContext from "../../../contexts/AuthContext";

const FiltersContainer = styled.div.attrs(() => ({
  className: "d-flex justify-content-start align-items-center"
}))`
  flex-direction: row;

  @media (max-width: 767px) {
    flex-direction: column;
  }
  margin-bottom: 1.5rem;
`;

const Filter = styled.div.attrs(() => ({
  className: "col-xs col-md-3 pl-0"
}))`
  @media (max-width: 767px) {
    margin-bottom: 0.75rem;
  }
`;

const ChronicConditionsHeader = ({ loadChronicConditionsByYearFn }) => {
  const { isBOCadmin } = useContext(AuthContext).state;
  const { wbcYears, selectedWbcYear, changeSelectedYear } = useContext(WBCYearsContext);
  const {
    nameSearchFilter,
    setNameSearchFilter,
    insuranceStatuses,
    insuranceStatusFilter,
    setInsuranceStatusFilter
  } = useContext(ChronicConditionsContext);

  const [addPlayerModalOpen, setAddPlayerModalOpen] = useState(false);

  const yearOptions = wbcYears.filter(isCurrentOrPastTournamentYear);

  const handleStatusChange = status => setInsuranceStatusFilter(status);

  const handleWBCYearChange = useCallback(({ year }) => changeSelectedYear(year), [changeSelectedYear]);

  const handleNameSearchFilter = search => setNameSearchFilter(search?.target?.value);

  return (
    <FiltersContainer>
      <Filter>
        <BestSelect name="yearDropdown" value={selectedWbcYear} options={yearOptions} onChange={handleWBCYearChange} />
      </Filter>
      <Filter>
        <BestSelect
          name="insuranceStatusDropdown"
          value={insuranceStatusFilter}
          options={insuranceStatuses}
          onChange={handleStatusChange}
        />
      </Filter>
      <Filter>
        <Input placeholder="type to search" value={nameSearchFilter} onChange={handleNameSearchFilter}></Input>
      </Filter>
      {isBOCadmin && (
        <Filter>
          <WBCPrimaryOutlinedButton onClick={() => setAddPlayerModalOpen(true)}>Add Player</WBCPrimaryOutlinedButton>
          <AddChronicConditionPlayerModal
            isOpen={addPlayerModalOpen}
            setIsOpen={setAddPlayerModalOpen}
            loadChronicConditionsByYearFn={loadChronicConditionsByYearFn}
          />
        </Filter>
      )}
    </FiltersContainer>
  );
};

ChronicConditionsHeader.propTypes = {
  loadChronicConditionsByYearFn: PropTypes.func.isRequired
};

export default ChronicConditionsHeader;
