import React, { useReducer, useMemo, useState, useContext } from "react";
import PropTypes from "prop-types";
import MLBPlayerSummaryContext from "../contexts/MLBPlayerSummaryContext";
import PositionsContext from "../contexts/PositionsContext";
import {
  ALL_ROSTERS,
  AVAILABLE_LIST,
  FINAL_ROSTER,
  INTEREST_LIST,
  PROVISIONAL_LIST,
  ROSTER_LIST_HIERARCHY
} from "../constants/RosterListConstants";

const DEFAULT_POSITIONS_FILTER_OPTION = {
  value: -1,
  label: "All Players",
  name: "All Players",
  orderNum: -1,
  playerType: null
};
const DEFAULT_ROSTER_LIST_OPTION = { label: ALL_ROSTERS, value: ALL_ROSTERS };
const DEFAULT_SORT_BY_OPTION = { label: "Sort Alpha", value: "alpha" };
const DEFAULT_STATUS_SELECTED = { label: "All Club Request Statuses", value: null };

const ROSTER_LIST_OPTIONS = [
  DEFAULT_ROSTER_LIST_OPTION,
  { label: INTEREST_LIST, value: INTEREST_LIST },
  { label: AVAILABLE_LIST, value: AVAILABLE_LIST },
  { label: PROVISIONAL_LIST, value: PROVISIONAL_LIST },
  { label: FINAL_ROSTER, value: FINAL_ROSTER }
];

const SORT_BY_OPTIONS = [DEFAULT_SORT_BY_OPTION, { label: "Sort Numerical", value: "numerical" }];

const INITIAL_STATE = {
  fedFilter: null,
  orgFilter: null,
  playerSummaries: [],
  positionFilter: DEFAULT_POSITIONS_FILTER_OPTION,
  rosterListFilter: DEFAULT_ROSTER_LIST_OPTION,
  sortBy: DEFAULT_SORT_BY_OPTION,
  playerStatuses: [],
  statusSelected: DEFAULT_STATUS_SELECTED,
  nameSearchFilter: null,
  sortFilters: {
    col: "lastName",
    dir: "ASC"
  },
  playersDisplayed: []
};

const MLBPlayerSummaryContextProvider = ({ children }) => {
  const { profilePositionsDropdown } = useContext(PositionsContext).state;

  const [fedFilter, setFedFilter] = useState(INITIAL_STATE.fedFilter);
  const [orgFilter, setOrgFilter] = useState(INITIAL_STATE.orgFilter);
  const [playerSummaries, setPlayerSummaries] = useState(INITIAL_STATE.playerSummaries);
  const [playersDisplayed, setPlayersDisplayed] = useState(INITIAL_STATE.playersDisplayed);
  const [positionFilter, setPositionFilter] = useState(INITIAL_STATE.positionFilter);
  const [rosterListFilter, setRosterListFilter] = useState(INITIAL_STATE.rosterListFilter);
  const [sortBy, setSortBy] = useState(INITIAL_STATE.sortBy);
  const [playerStatuses, setPlayerStatuses] = useState(INITIAL_STATE.playerStatuses);
  const [statusSelected, setStatusSelected] = useState(INITIAL_STATE.statusSelected);
  const [sortFilters, setSortFilters] = useState(INITIAL_STATE.sortFilters);

  const isOnRosterList = (highestRosterStatus, rosterList) => {
    const rosterListHierarchy = ROSTER_LIST_HIERARCHY[highestRosterStatus];
    return (rosterListHierarchy && rosterListHierarchy.includes(rosterList)) || rosterList === ALL_ROSTERS;
  };

  const filteredPlayerSummaries = useMemo(() => {
    return playerSummaries
      ?.filter(
        player =>
          !positionFilter ||
          positionFilter.value === DEFAULT_POSITIONS_FILTER_OPTION.value ||
          positionFilter.label === player.position
      )
      ?.filter(player => isOnRosterList(player.highestRoster, rosterListFilter.value));
  }, [fedFilter, orgFilter, playerSummaries, positionFilter, rosterListFilter, playersDisplayed]);

  const positionFilterOptions = [DEFAULT_POSITIONS_FILTER_OPTION].concat(
    profilePositionsDropdown.filter(p => p.playerType).sort((a, b) => a.orderNum - b.orderNum)
  );

  const resetState = () => {
    setPlayerSummaries(INITIAL_STATE.playerSummaries);
    setPositionFilter(INITIAL_STATE.positionFilter);
    setRosterListFilter(INITIAL_STATE.rosterListFilter);
    setSortBy(INITIAL_STATE.sortBy);
    setPlayerStatuses(INITIAL_STATE.playerStatuses);
  };

  const sortPlayerCounts = (playerCounts, alphaField) => {
    return sortBy?.value === "numerical"
      ? playerCounts.sort((a, b) => b.playerCount - a.playerCount)
      : playerCounts.sort((a, b) => a[alphaField].localeCompare(b[alphaField]));
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "sortPlayers":
        setPlayerSummaries(action.playersDisplayed);
        return {
          ...state,
          sortFilters: {
            ...state.sortFilters,
            col: action.col,
            dir: action.dir
          },
          playersDisplayed: [...action.playersDisplayed]
        };
      default:
        return { ...state };
    }
  };
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <MLBPlayerSummaryContext.Provider
      value={{
        state,
        dispatch,
        sortFilters,
        fedFilter,
        filteredPlayerSummaries,
        orgFilter,
        positionFilter,
        positionFilterOptions,
        resetState,
        rosterListFilter,
        rosterListOptions: ROSTER_LIST_OPTIONS,
        setFedFilter,
        setOrgFilter,
        setPlayerSummaries,
        setPositionFilter,
        setRosterListFilter,
        setSortBy,
        sortBy,
        sortByOptions: SORT_BY_OPTIONS,
        sortPlayerCounts,
        playerStatuses,
        playerSummaries,
        setPlayerStatuses,
        statusSelected,
        setStatusSelected
      }}
    >
      {children}
    </MLBPlayerSummaryContext.Provider>
  );
};

MLBPlayerSummaryContextProvider.propTypes = {
  children: PropTypes.object
};

export default MLBPlayerSummaryContextProvider;
