import React, { useReducer } from "react";
import PropTypes from "prop-types";
import PositionsContext from "../contexts/PositionsContext";

const initialState = {
  allPositions: [],
  playerPositions: [],
  profilePositions: [],
  allPositionsDropdown: [],
  playerPositionsDropdown: [],
  profilePositionsDropdown: [],
  posIdToName: {},
  posIdToAbbrev: {},
  posAbbrevToId: {}
};

const PositionsContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "initialize":
        return {
          ...state,
          positionsDropdown: action.positionsDropdown,
          playerPositionsDropdown: action.playerPositionsDropdown,
          profilePositionsDropdown: action.profilePositionsDropdown,
          posIdToName: action.posIdToName,
          posIdToAbbrev: action.posIdToAbbrev,
          posAbbrevToId: action.posAbbrevToId
        };
      case "setPositions":
        return { ...state, positions: action.positions };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <PositionsContext.Provider value={{ state, dispatch }}>{children}</PositionsContext.Provider>;
};

PositionsContextProvider.propTypes = {
  children: PropTypes.object
};

export default PositionsContextProvider;
