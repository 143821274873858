import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Moment from "moment";
import { InfoCard, FormColumn4Wide, FormInput, AlertConstants, BestLabel } from "best-common-react";
import RouteConstants from "../../constants/RouteConstants";
import ProspectivePlayersApi from "../../httpClients/ProspectivePlayersApi";
import WBCLoadingButton from "../../components/elements/WBCLoadingButton";
import WBCRadioButton from "../../components/elements/WBCRadioButton";
import CountryContext from "../../contexts/CountryContext";
import FormHelper from "../elements/FormHelper";
import PlayerMatchModal from "./PlayerMatchModal/PlayerMatchModal";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import { useAlert } from "../hooks/useAlert";
import { minFormDate } from "../../utils/DateUtils";

const Title = styled.h2`
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 300;
  color: ${props => props.theme["dark-grey"]};
`;

const Required = styled.span`
  color: ${props => (props.required ? props.theme["mlb-red"] : props.theme["dark-grey"])};
  padding-left: 5px;
`;

const Label = styled.span`
  color: ${props => (props.required ? props.theme["black"] : props.theme["dark-grey"])};
`;

const initialPlayer = {
  firstName: null,
  lastName: null,
  birthCountry: null,
  birthDate: null,
  ebisId: null,
  namePlayerSelected: true
};

const NewPlayerCard = withRouter(({ history }) => {
  // context && state
  const countryContext = useContext(CountryContext);
  const selectedTeamContext = useContext(SelectedTeamContext);
  const globalModalContext = useContext(GlobalModalContext);
  const [player, setPlayer] = useState(initialPlayer);
  const [addPlayerEnabled, setAddPlayerEnabled] = useState(false);
  const [matchData, setMatchData] = useState([]);
  const [matchModalStatus, toggleMatchModal] = useState(false);
  const [searchByEbisId, setSearchByEbisId] = useState(false);

  // hooks & variable(s)
  const showAlert = useAlert();

  // function(s)
  const resetPlayer = () => {
    setPlayer({
      ...player,
      firstName: null,
      lastName: null,
      birthCountry: null,
      birthDate: null,
      ebisId: null
    });
  };

  const checkMatchLogic = () => {
    if (player.namePlayerSelected) {
      player.birthCountryName = player.birthCountry.label;
      player.birthCountryId = player.birthCountry.value;
      player.playerFullName = `${player.lastName}, ${player.firstName}`;
      player.rosterStatusId = 1;
      player.ebisId = null;
      ProspectivePlayersApi.checkPlayerMatches(player)
        .then(data => {
          if (data && data.matches) {
            if (data.matches.length > 0) {
              data.matches.unshift(player);
              setMatchData(data.matches);
              toggleMatchModal(true);
            } else {
              createNewPlayer(player);
            }
            resetPlayer();
          }
        })
        .catch(({ response }) => {
          showAlert(response.data.message, AlertConstants.TYPES.DANGER);
        });
    } else {
      player.birthCountryName = null;
      player.birthCountryId = null;
      player.playerFullName = null;
      player.rosterStatusId = undefined;
      ProspectivePlayersApi.searchPlayerByEbisId(player.ebisId)
        .then(data => {
          searchPlayerByEbisId(data);
        })
        .catch(({ response }) => {
          resetPlayer();
          showAlert(response.data.message, AlertConstants.TYPES.DANGER);
        });
    }
  };

  const searchPlayerByEbisId = data => {
    resetPlayer();

    // note: ebis search can return multiple players because we search wbc first
    if (data && data.matches && data.matches.length > 0) {
      setSearchByEbisId(true);
      data.matches.unshift(player);
      setMatchData(data.matches);
      toggleMatchModal(true);
      return;
    }

    // Not found anywhere
    globalModalContext.dispatch({
      type: "openNoEbisMatchModal",
      content: player.ebisId
    });
  };

  const createNewPlayer = playerToCreate => {
    const { firstName, lastName, birthDate, birthCountryId } = playerToCreate;

    const selectedProfile = {
      fullName: {
        firstName,
        lastName
      },
      birthInfo: {
        birthDate,
        birthCountryId
      }
    };

    const fedTeamId = selectedTeamContext.state.team.teamId;
    const route = RouteConstants.PROFILE.replace(":playerId", RouteConstants.NEW_PROFILE_SUFFIX)
      .replace(":fedTeamId", fedTeamId)
      .replace(":profileType", RouteConstants.PLAYER);
    history.push(route, selectedProfile);
  };

  const selectNamePlayerOption = () => {
    setPlayer({
      ...player,
      ebisId: null,
      namePlayerSelected: true
    });
  };

  const selectEbisIdOption = () => {
    setPlayer({
      ...player,
      firstName: null,
      lastName: null,
      birthCountry: null,
      birthDate: null,
      namePlayerSelected: false
    });
  };

  // effect(s)
  useEffect(() => {
    if (player.namePlayerSelected) {
      setAddPlayerEnabled(
        FormHelper.areAllPropertiesDefined({
          firstName: player.firstName,
          lastName: player.lastName,
          birthCountry: player.birthCountry,
          birthDate: player.birthDate
        })
      );
    } else {
      setAddPlayerEnabled(FormHelper.isDefined(player.ebisId));
    }
  }, [player]);

  return (
    <div>
      <PlayerMatchModal
        modalStatus={matchModalStatus}
        toggleModal={toggleMatchModal}
        matchData={matchData}
        searchByEbisId={searchByEbisId}
        createPlayerFn={createNewPlayer}
      />
      <InfoCard className="mt-3">
        <div>
          <Title>New Player</Title>
          <div className="d-flex flex-wrap flex-lg-nowrap">
            <div className="d-flex align-items-center" style={{ width: "35px", paddingTop: "7px" }}>
              <WBCRadioButton checked={player.namePlayerSelected} onClick={selectNamePlayerOption} />
            </div>
            <div className="row flex-fill">
              <FormColumn4Wide className="pl-lg-0">
                <BestLabel>
                  <Label required={player.namePlayerSelected}>First Name</Label>
                  <Required required={player.namePlayerSelected}>*</Required>
                </BestLabel>
                <FormInput
                  type="text"
                  name="firstName"
                  isRequired={player.namePlayerSelected}
                  isDisabled={!player.namePlayerSelected}
                  value={player.firstName || ""}
                  onChangeFn={value => setPlayer({ ...player, firstName: value })}
                  label=""
                  editable
                />
              </FormColumn4Wide>
              <FormColumn4Wide>
                <BestLabel>
                  <Label required={player.namePlayerSelected}>Last Name</Label>
                  <Required required={player.namePlayerSelected}>*</Required>
                </BestLabel>
                <FormInput
                  type="text"
                  name="lastName"
                  isRequired={player.namePlayerSelected}
                  isDisabled={!player.namePlayerSelected}
                  value={player.lastName || ""}
                  onChangeFn={value => setPlayer({ ...player, lastName: value })}
                  label=""
                  editable
                />
              </FormColumn4Wide>
              <FormColumn4Wide>
                <BestLabel>
                  <Label required={player.namePlayerSelected}>Birth Country</Label>
                  <Required required={player.namePlayerSelected}>*</Required>
                </BestLabel>
                <FormInput
                  name="birthCountry"
                  type="select"
                  isRequired={player.namePlayerSelected}
                  isDisabled={!player.namePlayerSelected}
                  options={countryContext.state.countryDropdown}
                  value={player.birthCountry || ""}
                  onChangeFn={value => setPlayer({ ...player, birthCountry: value })}
                  label=""
                  editable
                />
              </FormColumn4Wide>
              <FormColumn4Wide className="pl-lg-0 pl-xl-3">
                <BestLabel>
                  <Label required={player.namePlayerSelected}>Birth Date</Label>
                  <Required required={player.namePlayerSelected}>*</Required>
                </BestLabel>
                <FormInput
                  name="birthDate"
                  type="date"
                  timeZone="America/New_York"
                  minDate={minFormDate}
                  value={(player.birthDate ? Moment(player.birthDate, "MM/DD/YYYY") : player.birthDate) || ""}
                  onChangeFn={value =>
                    setPlayer({ ...player, birthDate: value ? Moment(value).format("MM/DD/YYYY") : value })
                  }
                  isRequired={player.namePlayerSelected}
                  isDisabled={!player.namePlayerSelected}
                  label=""
                  editable
                  autoComplete="off"
                  popperPlacement={"bottom-start"}
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false
                    }
                  }}
                />
              </FormColumn4Wide>
            </div>
          </div>
          <div className="d-flex flex-wrap flex-lg-nowrap">
            <div className="d-flex align-items-center" style={{ width: "35px", paddingTop: "7px" }}>
              <WBCRadioButton checked={!player.namePlayerSelected} onClick={selectEbisIdOption} />
            </div>
            <div className="row w-100">
              <FormColumn4Wide className="pl-lg-0">
                <BestLabel>
                  <Label required={!player.namePlayerSelected}>Ebis ID</Label>
                  <Required required={!player.namePlayerSelected}>*</Required>
                </BestLabel>
                <FormInput
                  editable
                  type="text"
                  name="ebisId"
                  isRequired={!player.namePlayerSelected}
                  isDisabled={player.namePlayerSelected}
                  value={player.ebisId || ""}
                  label=""
                  onChangeFn={value => {
                    if (value.length <= 10) {
                      setPlayer({ ...player, ebisId: value.replace(/\D/g, "") });
                    }
                  }}
                />
              </FormColumn4Wide>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <WBCLoadingButton
              disabled={!addPlayerEnabled}
              text="Create Player"
              styles={{ width: "120px" }}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                checkMatchLogic();
              }}
            />
          </div>
        </div>
      </InfoCard>
    </div>
  );
});

export default NewPlayerCard;
