import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import PropTypes from "prop-types";
import PlayerMatchModalAddButton from "./PlayerMatchModalAddButton";
import { VerticalTable } from "best-common-react";
import { formatDate } from "../../../utils/DateUtils";

const ModalText = styled.div`
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1.71;
  color: ${props => props.theme["dark-grey"]};
`;

const TableWrapper = styled.div`
  padding-top: 15px;
  overflow-x: auto;

  table {
    table-layout: fixed;
  }
`;

const headers = [{ name: "" }, { name: "You Entered:" }, { name: "System Matches" }];

const PlayerMatchModalBodyContent = ({ matchData, matchMetaData, toggleModal }) => {
  const { hasEbisId, hasFederations } = matchMetaData;
  const Theme = useContext(ThemeContext);
  const rows = [
    {
      name: "",
      key: "",
      // eslint-disable-next-line react/display-name
      cellOverride: (row, _, colIndex) =>
        !colIndex ? null : <PlayerMatchModalAddButton data={row} toggleModal={toggleModal} />
    },
    {
      name: "Last, First",
      key: "playerFullName"
    },
    {
      name: "Birth Country",
      key: "birthCountryName"
    },
    {
      name: "Birth Date",
      key: "",
      // eslint-disable-next-line react/display-name
      cellOverride: row => formatDate(row.birthDate)
    }
  ];

  if (hasFederations) {
    rows.push({
      name: "Federations",
      key: "",
      // eslint-disable-next-line react/display-name
      cellOverride: row => (row.federations ? row.federations.join(", ") : "")
    });
  }

  if (hasEbisId) {
    rows.push({
      name: "EBIS ID",
      key: "ebisId"
    });
  }

  const TableStyle = {
    td: {
      backgroundColor: `${Theme.white} !important`,
      fontWeight: "normal !important"
    }
  };

  return (
    <div>
      <ModalText>Is the player you&apos;re entering one of the players below?</ModalText>
      <TableWrapper>
        <VerticalTable rows={rows} headers={headers} data={matchData} styles={TableStyle} />
      </TableWrapper>
    </div>
  );
};

PlayerMatchModalBodyContent.propTypes = {
  matchData: PropTypes.array.isRequired,
  matchMetaData: PropTypes.object.isRequired,
  toggleModal: PropTypes.func
};

PlayerMatchModalBodyContent.displayName = "BodyContent";

export default PlayerMatchModalBodyContent;
