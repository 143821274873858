import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import LabelConstants from "../../constants/LabelConstants";
import ProfileConstants from "../../constants/ProfileConstants";
import RouteConstants from "../../constants/RouteConstants";
import CountryContext from "../../contexts/CountryContext";
import DocumentsContext from "../../contexts/DocumentsContext";
import GlobalFedDropdownContext from "../../contexts/GlobalFedDropdownContext";
import ProfileContext from "../../contexts/ProfileContext";
import WBCProfileApi from "../../httpClients/WBCProfileApi";
import DocumentsContextProvider from "../../providers/DocumentsContextProvider";
import { getIsDirty, getIsValid, getSaveDocumentsRequest } from "../../selectors/DocumentSelectors";
import ButtonHolder from "../elements/ButtonHolder";
import FormHelper from "../elements/FormHelper";
import { WBCPrimaryButton } from "../elements/WBCPrimaryButton";
import WBCPrimaryOutlinedButton from "../elements/WBCPrimaryOutlinedButton";
import { useAlert } from "../hooks/useAlert";
import { useError } from "../hooks/useError";
import HorizontalNav from "../navigation/HorizontalNav";
import DiscardPrompt from "../protected/DiscardPrompt";
import WBCAdminAccess from "../protected/WBCAdminAccess";
import ProfileTabManager from "./ProfileTabManager";
import AuthContext from "../../contexts/AuthContext";

const spread = obj => obj || {};

// NOTE: once backend profile is flattened, this flatten function is obsolete
const flattenProfile = nestedProfile => {
  // flatten affiliation
  const affiliation = { ...(nestedProfile.affiliation || {}) };
  affiliation.club = affiliation.clubString;
  affiliation.clubString = undefined;

  // flatten stats
  const stats = { ...(nestedProfile.stats || {}) };
  stats.weight = stats.weight || null;
  stats.height = stats.height ? parseInt(stats.height) || null : null;
  stats.throws = stats.throwsString;
  stats.throwsString = undefined;

  // flatten rosterInfo
  const rosterInfo = {
    ...(nestedProfile.rosterInfo || {}),
    fortyManRosterFlagAsString: undefined,
    injuredListFlagAsString: undefined,
    suspendedListFlagAsString: undefined
  };

  // flatten address
  const address = nestedProfile.address || {};
  const streetAddress = address.streetAddress || {};

  return {
    // nested
    ...stats,
    ...rosterInfo,
    ...affiliation,
    ...spread(nestedProfile.emailAddress),
    ...spread(nestedProfile.fullName),
    ...spread(nestedProfile.birthInfo),
    ...spread(nestedProfile.phoneNumber),
    ...spread(nestedProfile.languages),
    // address
    city: address.addressCity,
    countryId: address.addressCountryId,
    provinceId: address.addressProvinceId,
    stateId: address.addressStateId,
    addressType: address.addressType,
    zipCode: address.addressZipCode,
    streetAddress1: streetAddress.streetAddress1,
    streetAddress2: streetAddress.streetAddress2,
    streetAddress3: streetAddress.streetAddress3,
    // misc
    ebisId: nestedProfile.ebisId,
    pastWbcFlag: nestedProfile.pastWbcFlag,
    eligibilityStatusId: (nestedProfile.eligibility || {}).status,
    notes: nestedProfile.notes
  };
};

// build the profile by replacing certain fields
const buildProfile = (profileIdParam, flatProfile) => {
  const profileId = parseInt(profileIdParam);

  return {
    profileId: isNaN(profileId) ? null : profileId,
    ebisId: flatProfile.ebisId,
    pastWbcFlag: flatProfile.pastWbcFlag,
    notes: flatProfile.notes,
    fullName: {
      firstName: flatProfile.firstName,
      lastName: flatProfile.lastName,
      middleName: flatProfile.middleName,
      extendedLastName: flatProfile.extendedLastName
    },
    birthInfo: {
      birthDate: flatProfile.birthDate,
      birthCity: flatProfile.birthCity,
      birthStateId: flatProfile.birthStateId,
      birthProvinceId: flatProfile.birthProvinceId,
      birthCountryId: flatProfile.birthCountryId
    },
    languages: {
      primaryLanguageId: flatProfile.primaryLanguageId,
      secondaryLanguageId: flatProfile.secondaryLanguageId
    },
    affiliation: {
      orgId: flatProfile.orgId,
      clubId: flatProfile.clubId,
      clubString: flatProfile.club
    },
    stats: {
      bats: flatProfile.bats,
      height: flatProfile.height,
      weight: flatProfile.weight,
      positionId: flatProfile.positionId,
      throwsString: flatProfile.throws
    },
    phoneNumber: {
      countryCode: flatProfile.countryCode,
      phoneType: flatProfile.phoneType,
      phoneNumber: flatProfile.phoneNumber
    },
    emailAddress: {
      emailType: flatProfile.emailType,
      emailAddress: flatProfile.emailAddress
    },
    address: {
      streetAddress: {
        streetAddress1: flatProfile.streetAddress1,
        streetAddress2: flatProfile.streetAddress2,
        streetAddress3: flatProfile.streetAddress3
      },
      addressType: flatProfile.addressType,
      addressCity: flatProfile.city,
      addressStateId: flatProfile.stateId,
      addressProvinceId: flatProfile.provinceId,
      addressZipCode: flatProfile.zipCode,
      addressCountryId: flatProfile.countryId
    },
    rosterInfo: {
      fortyManRosterFlag: flatProfile.fortyManRosterFlag,
      injuredListFlag: flatProfile.injuredListFlag,
      suspendedListFlag: flatProfile.suspendedListFlag
    }
  };
};

const validationKeys = {
  name: ["firstName", "lastName"],
  birth: ["birthDate"],
  stats: ["positionId"],
  address: []
};

const InnerProfileComponent = ({ history, isNew, isStaff, fedTeamId, profileId, newProfile }) => {
  // hooks
  const discardRef = useRef(null);
  const historyCount = useRef(0);
  const [forceReroute, setForceReroute] = useState(false);
  const countryContext = useContext(CountryContext);
  const profileContext = useContext(ProfileContext);
  const globalFedDropdownContext = useContext(GlobalFedDropdownContext);
  const {
    state: documentsState,
    actions: { reloadDocuments }
  } = useContext(DocumentsContext);
  const showAlert = useAlert();
  const showErrors = useError();
  const authContext = useContext(AuthContext);

  // variables
  const { dispatch } = profileContext;
  const { dispatch: fedDispatch } = globalFedDropdownContext;
  const { countryIdToName } = countryContext.state;
  const { isBOCadmin, isFedAdmin, isTeamCoordinator } = authContext.state;
  const canEdit = isBOCadmin || isFedAdmin || isTeamCoordinator;

  // documents
  const isDocumentsDirty = getIsDirty(documentsState);
  const isDocumentsValid = getIsValid(documentsState);
  const { loading: areDocumentsLoading } = documentsState.documents;

  // profile info
  const {
    firstName,
    lastName,
    birthCountryId,
    emailAddress,
    countryId,
    isDirty: isProfileDirty,
    isSaving: isProfileSaving
  } = profileContext.state;

  // dirty
  const isDirty = forceReroute ? false : isProfileDirty || isDocumentsDirty;

  // saving
  const isSaving = isProfileSaving || areDocumentsLoading;

  // name
  const title = isNew ? `New ${isStaff ? "Staff" : "Player"}` : `${firstName || ""} ${lastName || ""}`.trim();

  // functions
  const validateForm = () => {
    // update birth validation keys
    const birth = ["birthDate", "birthCountryId", "birthCity"];
    const birthCountry = countryIdToName[birthCountryId];
    if (!isStaff) {
      if (birthCountry === "United States") {
        birth.push("birthStateId");
      } else if (birthCountry === "Canada") {
        birth.push("birthProvinceId");
      }
    }

    // update address fields
    const country = countryIdToName[countryId];

    const validation = {
      ...validationKeys,
      birth: isStaff ? validationKeys.birth : birth,
      stats: isStaff ? validationKeys.stats : ["bats", "height", "weight", "positionId", "throws"],
      address: validationKeys.address
        .concat(country === "United States" ? ["stateId"] : country === "Canada" ? ["provinceId"] : [])
        .concat(ProfileConstants.zipCodeCountries.has(country) ? ["zipCode"] : [])
    };

    const keys = [].concat(...Object.values(validation));
    return (
      FormHelper.validateObject(profileContext.state, keys) &&
      (!emailAddress || emailAddress?.length === 0 || FormHelper.isValidEmail(emailAddress)) &&
      isDocumentsValid
    );
  };

  const saveRequest = useCallback(
    (override = false) => {
      dispatch({ type: "saveRequest" });
      WBCProfileApi.updateProfile({
        override,
        fedTeamId: parseInt(fedTeamId),
        updateProfileRequest: {
          [`${isStaff ? "staff" : "player"}Profile`]: buildProfile(profileId, profileContext.state),
          saveDocumentsRequest: getSaveDocumentsRequest(documentsState)
        }
      })
        .then(response => {
          if (response.status.status === 200) {
            showAlert("Saved");
            dispatch({ type: "saveSuccess" });

            // should trigger the go back after a re-render
            if (isNew) {
              setForceReroute(true);
            }
          } else if (response.status.status === 202) {
            // on document fail, show specific message
            const { firstName: name, lastName: givenName } = response.profile.fullName;
            const message = `Documents for ${givenName}, ${name} failed to upload`;

            dispatch({ type: "saveSuccess" });
            showAlert("Player saved");
            showAlert(message, "danger");

            /**
             * if there is a document error but profile saved,
             * we should go back to prevent duplicate profile
             * being saved
             */
            if (isNew) {
              setForceReroute(true);
            }
          } else {
            showAlert(response.message, "danger");
          }

          reloadDocuments();
        })
        .catch(e => {
          dispatch({ type: "saveFailure" });
          if (e.response) {
            showErrors(e.response.data.message, () => {
              saveRequest(true);
            });
          } else {
            throw e;
          }
        });
    },
    [
      profileContext.state,
      profileId,
      fedTeamId,
      isNew,
      isStaff,
      dispatch,
      showAlert,
      showErrors,
      documentsState,
      reloadDocuments
    ]
  );

  const onSave = useCallback(() => saveRequest(), [saveRequest]);
  const onClose = useCallback(() => {
    discardRef.current = "cancel";
    history.go(-1 + -1 * historyCount.current);
  }, [discardRef, history, historyCount]);
  const onDiscard = () => dispatch({ type: "cleanForm" });

  // form valid
  const isFormValid = validateForm();

  // effects
  // get the profile from the network call
  useEffect(() => {
    if (!isNew) {
      WBCProfileApi.getProfileByTeamAndId(fedTeamId, profileId).then(profile => {
        // NOTE: once backend profile is flattened, the flatten step is obsolete
        dispatch({ type: "initialize", profile: flattenProfile(profile) });
      });
    } else {
      dispatch({ type: "initialize", profile: flattenProfile(newProfile) });
    }
  }, [dispatch, isNew, fedTeamId, profileId, newProfile]);

  // hide dropdown
  useEffect(() => {
    fedDispatch({
      type: "setShowingState",
      showingState: false
    });
  }, [fedDispatch]);

  // force re-route
  useEffect(() => {
    if (forceReroute) {
      history.goBack();
    }
  }, [forceReroute, history]);

  return (
    <>
      <DiscardPrompt ref={discardRef} isDirty={isDirty} onConfirm={onSave} onDiscard={onDiscard} />
      <HorizontalNav title={`${title}${isDirty ? "*" : ""}`}>
        <ButtonHolder>
          <div className="mr-2">
            {canEdit && (
              <div>
                <WBCPrimaryButton className="btn-md" onClick={onSave} disabled={isSaving || !isDirty || !isFormValid}>
                  Save
                </WBCPrimaryButton>
              </div>
            )}
          </div>
          <div>
            <WBCPrimaryOutlinedButton onClick={onClose}>Close</WBCPrimaryOutlinedButton>
          </div>
        </ButtonHolder>
      </HorizontalNav>
      <ProfileTabManager count={historyCount} />
    </>
  );
};

const ProfileComponent = withRouter(({ history, location, match: { params } }) => {
  // variables
  const { fedTeamId, profileType, playerId: profileId } = params;

  // route
  const isNew = profileId === RouteConstants.NEW_PROFILE_SUFFIX;
  const isStaff = profileType === RouteConstants.STAFF;

  // documents
  const labelTypeId = LabelConstants.LABEL_TYPE[isStaff ? "STAFF" : "PLAYER"];

  const props = { fedTeamId, profileId, history, isNew, isStaff, newProfile: location.state || {} };
  return (
    <DocumentsContextProvider
      labelTypeId={labelTypeId}
      profileId={parseInt(profileId) || null}
      fedTeamId={parseInt(fedTeamId) || null}
    >
      <InnerProfileComponent {...props} />
    </DocumentsContextProvider>
  );
});

InnerProfileComponent.propTypes = {
  isNew: PropTypes.bool,
  isStaff: PropTypes.bool,
  fedTeamId: PropTypes.string,
  profileId: PropTypes.string,
  history: PropTypes.shape({
    go: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
  }).isRequired,
  newProfile: PropTypes.object
};

export default ProfileComponent;
