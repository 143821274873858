import React, { useState, useContext, useEffect, useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from "lodash";
import { BestSelect, FormInput, BestLabel, RequiredInput, FormColumn2Wide } from "best-common-react";
import { Required } from "../shared/ProfileComponents";
import ClubsContext from "../../../contexts/ClubsContext";
import PositionsContext from "../../../contexts/PositionsContext";
import ProfileContext from "../../../contexts/ProfileContext";
import WBCAccessControl from "../../protected/WBCAccessControl";
import DisabledInputStyle from "../../elements/DisabledInputStyle";
import DesktopTabletView from "../../temp/DesktopTabletView";
import MobileView from "../../temp/MobileView";
import UncontrolledFormInput from "../../elements/UncontrolledFormInput";
import PositionKeyIcon from "../../elements/PositionKeyIcon";

const Text = styled.div`
  font-size: 14px;
  padding: 0.5rem 0.25rem;
`;

const HeightLabelContainer = styled.div`
  display: flex;
  min-width: 65px;
`;

const WeightInputContainer = styled.div`
  display: flex;
  min-width: 49px;
`;

const HiddenLabel = styled.div`
  display: flex;
  visibility: hidden;
`;

const InchesContainer = styled.div`
  min-width: 54px;
`;

const SuspendedRow = styled.div.attrs(() => ({
  className: "row"
}))`
  @media screen and (max-width: 1199px) {
    display: none !important;
  }
`;

const feetOptions = [
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" }
];

const inchesOptions = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" }
];

const boolToString = val => (val === undefined || val === null ? "" : val ? "Yes" : "No");

const BaseballRightForm = ({ isStaff }) => {
  // hooks
  const clubsContext = useContext(ClubsContext);
  const positionsContext = useContext(PositionsContext);
  const profileContext = useContext(ProfileContext);
  const [clubOptions, setClubOptions] = useState([]);

  // variables
  const { clubs, clubIdToName } = clubsContext.state;
  const { posIdToAbbrev, profilePositionsDropdown: positionsDropdown } = positionsContext.state;
  const {
    club,
    orgId,
    clubId,
    ebisId,
    positionId,
    height,
    weight,
    injuredListFlag,
    suspendedListFlag
  } = profileContext.state;

  // bio
  const hasHeight = height !== null && height !== undefined;
  const feet = hasHeight ? Math.floor(height / 12).toString() : "";
  const inches = hasHeight ? (height % 12).toString() : "";
  const heightFt = {
    value: feet,
    label: feet
  };
  const heightIn = {
    value: inches,
    label: inches
  };

  // position
  const positionOptions = _.sortBy(positionsDropdown, ["orderNum"]);

  // functions
  const findClubsForOrg = useCallback(() => {
    const clubMap = {};
    clubs.forEach(ballClub => {
      if (ballClub.orgId === orgId) {
        clubMap[ballClub.clubId] = {
          value: ballClub.clubId,
          label: ballClub.clubName,
          clubId: ballClub.clubId
        };
      }
    });
    return Object.values(clubMap);
  }, [clubs, orgId]);

  const onProfileChange = (value, key) => {
    profileContext.dispatch({
      key,
      value,
      type: "setProfileInfo"
    });
  };

  const onHeightChange = (value, key) => {
    const val = parseInt(value);
    const ins = parseInt(inches || "0");
    onProfileChange(key === "heightFt" ? val * 12 + ins : feet * 12 + val, "height");
  };

  // effects
  // update clubOptions for the selected org
  useEffect(() => {
    setClubOptions(findClubsForOrg());
  }, [findClubsForOrg]);

  return (
    <div className="col-xl-6 col-lg-12">
      <WBCAccessControl
        input={isDisabled => (
          <div>
            <div className="row">
              <DesktopTabletView minWidth={1200}>
                <div className="col">
                  {orgId === 1 || orgId === 5 ? (
                    <UncontrolledFormInput
                      id="clubTextField"
                      label="Club"
                      disabled={isDisabled}
                      value={club}
                      postChange={value => onProfileChange(value, "club")}
                    />
                  ) : (
                    <FormInput
                      editable
                      isDisabled={isDisabled}
                      name="clubDropdown"
                      label="Club"
                      type="select"
                      isRequired={false}
                      options={clubOptions}
                      inputStyle={DisabledInputStyle}
                      value={{
                        value: clubId,
                        label: clubIdToName[clubId] || ""
                      }}
                      onChangeFn={value => onProfileChange(value.clubId, "clubId")}
                    />
                  )}
                </div>
              </DesktopTabletView>
              <MobileView maxWidth={1199}>
                <div className="col">
                  <BestLabel>
                    Position{isDisabled ? null : <Required>*</Required>}
                    <PositionKeyIcon />
                  </BestLabel>
                  <FormInput
                    editable
                    label=""
                    isDisabled={isDisabled}
                    name="positionDropdown"
                    type="select"
                    options={positionOptions}
                    isRequired={!isDisabled}
                    value={{
                      value: positionId,
                      label: posIdToAbbrev[positionId] || ""
                    }}
                    onChangeFn={value => onProfileChange(value.value, "positionId")}
                  />
                </div>
              </MobileView>
            </div>
            <div className={`row ${isDisabled && !isStaff ? "mt-1" : ""}`.trim()}>
              <div className="col">
                <div className="d-flex">
                  <div id="required-input-with-label">
                    <HeightLabelContainer>
                      <BestLabel required={!isStaff && !isDisabled}>Height</BestLabel>
                    </HeightLabelContainer>
                    <div className="d-flex">
                      {isStaff ? (
                        <BestSelect
                          styles={{
                            control: {
                              minWidth: "40px"
                            }
                          }}
                          isDisabled={isDisabled}
                          name="heightFt"
                          options={feetOptions}
                          value={heightFt}
                          onChange={value => onHeightChange(value.value, "heightFt")}
                        />
                      ) : (
                        <BestSelect
                          styles={{
                            control: {
                              minWidth: "40px",
                              borderColor: !hasHeight ? "red" : "rgb(206, 212, 218)"
                            }
                          }}
                          isDisabled={isDisabled}
                          name="heightFt"
                          options={feetOptions}
                          value={heightFt}
                          onChange={value => onHeightChange(value.value, "heightFt")}
                        />
                      )}
                      <div className="pl-0">
                        <Text>ft</Text>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div id="required-input-with-label">
                      <HiddenLabel>
                        <BestLabel required={!isStaff && !isDisabled}>Inches</BestLabel>
                      </HiddenLabel>
                      <div className="d-flex">
                        <InchesContainer>
                          {isStaff ? (
                            <BestSelect
                              styles={{
                                control: {
                                  minWidth: "40px"
                                }
                              }}
                              isDisabled={isDisabled}
                              name="heightIn"
                              options={inchesOptions}
                              value={heightIn}
                              onChange={value => onHeightChange(value.value, "heightIn")}
                            />
                          ) : (
                            <BestSelect
                              styles={{
                                control: {
                                  minWidth: "40px",
                                  borderColor: !hasHeight ? "red" : "rgb(206, 212, 218)"
                                }
                              }}
                              isDisabled={isDisabled}
                              name="heightIn"
                              options={inchesOptions}
                              value={heightIn}
                              onChange={value => onHeightChange(value.value, "heightIn")}
                            />
                          )}
                        </InchesContainer>
                        <div className="pl-0">
                          <Text>in</Text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div id="required-input-with-label">
                  <div className="d-flex">
                    <BestLabel required={!isStaff && !isDisabled}>Weight</BestLabel>
                  </div>
                  <RequiredInput>
                    <WeightInputContainer>
                      <UncontrolledFormInput
                        id="weight"
                        number
                        numberLimit={3}
                        disabled={isDisabled}
                        required={!isStaff && !isDisabled}
                        value={weight}
                        postChange={value => {
                          if (!isNaN(value)) {
                            onProfileChange(value, "weight");
                          }
                        }}
                      />
                      <div className="pl-0 pr-0">
                        <Text>lbs</Text>
                      </div>
                    </WeightInputContainer>
                  </RequiredInput>
                </div>
              </div>
            </div>
          </div>
        )}
      />
      {ebisId ? (
        <SuspendedRow>
          <FormColumn2Wide>
            <FormInput
              editable
              isDisabled
              type="text"
              name="disabledInputWithText"
              label="IL"
              value={boolToString(injuredListFlag)}
              inputStyle={DisabledInputStyle}
            />
          </FormColumn2Wide>
          <FormColumn2Wide>
            <FormInput
              editable
              isDisabled
              type="text"
              name="disabledInputWithText"
              label="Suspended List"
              value={boolToString(suspendedListFlag)}
              inputStyle={DisabledInputStyle}
            />
          </FormColumn2Wide>
        </SuspendedRow>
      ) : null}
    </div>
  );
};

BaseballRightForm.propTypes = {
  isStaff: PropTypes.bool
};

export default BaseballRightForm;
