import React, { useContext } from "react";
import PropTypes from "prop-types";
import WBCModal from "../../elements/WBCModal";
import PlayerMatchModalBody from "./PlayerMatchModalBody";
import PlayerMatchModalFooter from "./PlayerMatchModalFooter";
import SelectedTeamContext from "../../../contexts/SelectedTeamContext";

/**
 * Condense matches with the same match profile Id.
 * The array should always be small, so we can do other
 * logic over the list later
 */
const condenseMatches = (enteredData, matches, currentTeamId) => {
  const matchMap = {};
  const perfectMatchMap = {};
  matches.forEach(oldMatch => {
    const { matchProfileId, ebisId, birthCountry, fullName, birthDate, team, profileId } = oldMatch;
    const perfectMatch =
      !enteredData.ebisId &&
      fullName.toLowerCase() === enteredData.playerFullName.toLowerCase() &&
      birthCountry.countryName === enteredData.birthCountry.label &&
      birthDate === enteredData.birthDate;
    const key = matchProfileId || ebisId;
    const match = perfectMatchMap[key] || matchMap[key] || { federations: [], isOnOwnFedTeam: false };
    match.ebisId = ebisId;
    match.birthCountryName = birthCountry.countryName;
    match.playerFullName = fullName;
    match.birthDate = birthDate;
    match.profileId = match.profileId || matchProfileId || undefined;

    // check for federations and if on the current team
    if (team && team.fedTeamId) {
      match.isOnOwnFedTeam = match.isOnOwnFedTeam || team.fedTeamId === currentTeamId;
      if (team.fedTeamId === currentTeamId) {
        match.profileId = profileId;
      }
      match.federations.push(team.country.countryCode);
    }

    // add to map
    if (perfectMatch) {
      perfectMatchMap[key] = match;
    } else {
      matchMap[key] = match;
    }
  });

  return Object.keys(perfectMatchMap)
    .map(k => perfectMatchMap[k])
    .concat(Object.keys(matchMap).map(k => matchMap[k]));
};

const PlayerMatchModal = ({ modalStatus, toggleModal, matchData, createPlayerFn, searchByEbisId }) => {
  // context(s)
  const selectedTeamContext = useContext(SelectedTeamContext);

  // variables
  let indexOfOwnFedTeam = -1;
  const { teamId } = selectedTeamContext.state.team || {};
  const enteredData = matchData[0];
  const matches = condenseMatches(enteredData, matchData.slice(1), teamId);
  const matchMetaData = {
    hasEbisId: false,
    hasFederations: false,
    hasProfileId: false
  };

  // calculate matchData metaData (i.e. contains ebisID, federations, etc.)
  matches.forEach((match, i) => {
    if (match.isOnOwnFedTeam) indexOfOwnFedTeam = i;
    if (match.ebisId) matchMetaData.hasEbisId = true;
    if (match.profileId) matchMetaData.hasProfileId = true;
    if (match.federations.length) matchMetaData.hasFederations = true;
  });

  // move own match to 1st item
  if (indexOfOwnFedTeam > 0) {
    const match = matches[indexOfOwnFedTeam];
    matches.splice(indexOfOwnFedTeam, 1);
    matches.unshift(match);
  }

  // put input first in array
  matches.unshift(enteredData);
  const headerText = `The info entered matches ${matchMetaData.hasProfileId ? "existing player(s)" : "an EBIS player"}`;

  return (
    <WBCModal isOpen={modalStatus} toggleModal={toggleModal} headerText={headerText}>
      <PlayerMatchModalBody matchData={matches} matchMetaData={matchMetaData} toggleModal={toggleModal} />
      <PlayerMatchModalFooter
        matchData={matches}
        toggleModal={toggleModal}
        createPlayerFn={createPlayerFn}
        searchByEbisId={searchByEbisId}
      />
    </WBCModal>
  );
};

PlayerMatchModal.propTypes = {
  modalStatus: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  matchData: PropTypes.array.isRequired,
  createPlayerFn: PropTypes.func.isRequired,
  searchByEbisId: PropTypes.bool
};

export default PlayerMatchModal;
