import React from "react";
import ProspectivePlayersProvider from "../ProspectivePlayersProvider";
import PropTypes from "prop-types";
import InterestListProvider from "../InterestListProvider";
import ProfileContextProvider from "../ProfileContextProvider";
import PlayerEligibilityProvider from "../PlayerEligibilityProvider";
import RosterContextProvider from "../RosterContextProvider";
import MLBPlayerSummaryContextProvider from "../MLBPlayerSummaryContextProvider";
import MLBPlayerRequestContextProvider from "../MLBPlayerRequestContextProvider";
import ChronicConditionsContextProvider from "../ChronicConditionsContextProvider";
import EmailNotificationsContextProvider from "../EmailNotificationsContextProvider";

const ProfileContextProviderWrapper = ({ children }) => {
  return (
    <ProfileContextProvider>
      <ProspectivePlayersProvider>
        <InterestListProvider>
          <PlayerEligibilityProvider>
            <RosterContextProvider>
              <MLBPlayerSummaryContextProvider>
                <MLBPlayerRequestContextProvider>
                  <ChronicConditionsContextProvider>
                    <EmailNotificationsContextProvider>{children}</EmailNotificationsContextProvider>
                  </ChronicConditionsContextProvider>
                </MLBPlayerRequestContextProvider>
              </MLBPlayerSummaryContextProvider>
            </RosterContextProvider>
          </PlayerEligibilityProvider>
        </InterestListProvider>
      </ProspectivePlayersProvider>
    </ProfileContextProvider>
  );
};

ProfileContextProviderWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default ProfileContextProviderWrapper;
