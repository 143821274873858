import BaseApi from "./BaseApi";

export default class WBCProfileApi extends BaseApi {
  static getProfileByTeamAndId = (fedTeamId, profileId) => this.get(`federationteam/${fedTeamId}/profile/${profileId}`);

  static updateProfile = ({ updateProfileRequest, fedTeamId, override = false }) =>
    this.put(`federationteam/${fedTeamId}/profile?override=${override}`, updateProfileRequest);

  static copyPlayerProfileToTeam = (fedTeamId, profileIdToCopy) =>
    this.put(`federationteam/${fedTeamId}/profile/${profileIdToCopy}`);

  static copyEbisPlayerProfileToTeam = (fedTeamId, ebisIdToCopy) =>
    this.put(`federationteam/${fedTeamId}/ebis/${ebisIdToCopy}`);

  static getPlayerProfilesFedTeamId = fedTeamId => this.get(`playerProfile/federationteam/${fedTeamId}`);

  static getAllTeamsPlayerHistoryById = profileId => this.get(`playerhistory/allfederations?profileId=${profileId}`);

  static deletePlayerHistoryById = playerHistoryId => this.delete(`playerhistory?playerHistoryId=${playerHistoryId}`);
}
