import React from "react";
import PropTypes from "prop-types";
import WBCPrimaryOutlinedButton from "../../elements/WBCPrimaryOutlinedButton";

const PlayerMatchModalFooterContent = ({ matchData, toggleModal, createPlayerFn, searchByEbisId }) => {
  return (
    <div>
      {searchByEbisId ? (
        <div className={"pl-2"}>
          <WBCPrimaryOutlinedButton
            onClick={event => {
              event.stopPropagation();
              toggleModal(false);
            }}
          >
            Cancel
          </WBCPrimaryOutlinedButton>
        </div>
      ) : (
        <div className={"d-flex"}>
          <WBCPrimaryOutlinedButton
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              createPlayerFn(matchData[0]);
            }}
            styles={{ width: "auto" }}
          >
            Continue Creating New Player
          </WBCPrimaryOutlinedButton>

          <div className={"pl-2"}>
            <WBCPrimaryOutlinedButton
              onClick={event => {
                event.stopPropagation();
                toggleModal(false);
              }}
            >
              Cancel
            </WBCPrimaryOutlinedButton>
          </div>
        </div>
      )}
    </div>
  );
};

PlayerMatchModalFooterContent.propTypes = {
  matchData: PropTypes.array.isRequired,
  toggleModal: PropTypes.func,
  createPlayerFn: PropTypes.func.isRequired,
  searchByEbisId: PropTypes.bool
};

PlayerMatchModalFooterContent.displayName = "FooterContent";

export default PlayerMatchModalFooterContent;
